import Cookies from 'js-cookie'
import {isWx} from '@/wechat'
export function getToken() { // 查看token
                             // return localStorage.getItem(process.env.VUE_APP_TOKEN)
  return Cookies.get(process.env.VUE_APP_TOKEN)
}

export function setToken(token) {  //设置token
                                   //localStorage.setItem(process.env.VUE_APP_TOKEN,token)
  Cookies.set(process.env.VUE_APP_TOKEN, token, {expires: 1})

}

export function removeToken() {  //清楚token
  // localStorage.removeItem(process.env.VUE_APP_TOKEN)
  Cookies.remove(process.env.VUE_APP_TOKEN)

}

export function isMobile() {
  if (isWx()) {
    return true
  }
  if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
    return true; // 移动端
  } else {
    return false; // PC端
  }
}
