import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    imgRoot:process.env.VUE_APP_BASE_API,  // 图片路径
    orderTime:{},  //  公众号选择的预约
    locale:window.sessionStorage.locale || 'zh',
    websiteste:0,//网站状态，0：中文，1：英文。
    ymindex:0,
    // 中文:  http://fuyang.vaiwan.com
    // 英文:  http://fuyang-manage.vaiwan.com
    webApi:window.sessionStorage.api || "https://www.qsxbwg.com/api/"
  },
  mutations: {
    changeOrderTimer(state,data){
        state.orderTime = data
    },
    changeLocale(state,data){
      try{
          window.sessionStorage.locale= data;
        if(data == 'en'){
          window.sessionStorage.api = 'http://fuyang-manage.vaiwan.com/api/'
        }else{
          window.sessionStorage.api = 'http://fuyang.vaiwan.com/api/'
        }
      }catch {}
      //state.locale = data
    }
  },
  actions: {
  },
  modules: {
  }
})
